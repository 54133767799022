<template>
  <div>
    <span class="back" @click="$router.go(-1)"> <font-awesome-icon icon="chevron-left" /> Volver </span>
    <div class="content">
      <step :step="2" />

      <div class="datepicker-container">
        <date-picker
          v-model="dateSelected"
          valueType="format"
          :transfer="true"
          @change="changeDate"
          :disabled-date="disabledDate"
          :open="true"
        ></date-picker>
      </div>
      <div class="schedule-text" v-if="schedulesToShow.length > 0">
        Horarios Disponibles para la fecha {{ dateSelected }}
      </div>

      <div class="schedules" v-if="schedulesToShow.length > 0">
        <div class="card-container">
          <schedule-card
            v-for="item in schedulesToShow"
            :key="item.id"
            :item="item"
            :day="dateSelected"
          ></schedule-card>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12 col-md-12">
          <button
            type="button"
            class="btn btn-success btn-save"
            @click="goToClient"
            :disabled="!($store.state.scheduleAvailable && $store.state.scheduleAvailable.hour.id)"
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/es';
import { mapGetters } from "vuex";
import ScheduleCard from "@/components/ScheduleCard";
import Step from "@/components/Step";
import "vue2-datepicker/index.css";
export default {
  components: { DatePicker, ScheduleCard, Step },

  data() {
    return {
      dateSelected: "",
      schedulesToShow: [],
      lang: 'es',
    };
  },
  methods: {
    disabledDate(date) {
      return !this.dateEnabled.includes(date.getTime());
    },
    changeDate() {
      this.schedulesToShow = this.schedules[this.dateSelected].professionals.sort((a, b) => a.name.localeCompare(b.name));
    },
    goToClient() {
      if (this.$store.state.scheduleAvailable && this.$store.state.scheduleAvailable.hour.id) {
        this.$router.push("/client");
      }
    },
  },
  created() {
    if (this.schedules.length == 0) {
      this.$router.push("/atencion-veterinaria");
    }
  },
  mounted() {
    this.dateSelected = this.daysAvailable[0];
    this.schedulesToShow = this.schedules[this.dateSelected].professionals
      ? this.schedules[this.dateSelected].professionals.sort((a, b) => a.name.localeCompare(b.name))
      : [];
  },

  computed: {
    ...mapGetters(["daysAvailable", "schedules"]),

    dateEnabled() {
      return this.daysAvailable.map((item) => {
        const date = new Date(item);
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + 1);
        return date.getTime();
      });
    },
  },
};
</script>

<style>
.mx-datepicker {
  left: 40%;
  visibility: hidden;
  /*width: 100%;*/
}

.mx-calendar-content .cell.active {
  background-color: #1e7e34;
  border-radius: 50%;
}
@media (min-width: 1000px) and (max-width: 2000px) {
  .mx-datepicker-main,
  .mx-datepicker-popup {
    position: absolute !important;
    top: 55% !important;
  }
}

@media (max-width: 768px) {
  .mx-datepicker-main,
  .mx-datepicker-popup {
    left: 35% !important;
        top: 50% !important;

  }
}
@media (max-width: 600px) {
  .mx-datepicker-main,
  .mx-datepicker-popup {
    top: 50% !important;
    left: 11% !important;
  }
}
@media (max-width: 425px) {
  .mx-datepicker-main,
  .mx-datepicker-popup {
    top: 55% !important;
    left: 21% !important;
  }
}
</style>
<style scoped>
.datepicker-container {
  position: relative;
  min-height: 290px;
  margin-bottom: 6%;
}

.content {
  position: relative;
}
.btn-save {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: 700;
}
.schedules {
  height: 500px;
  overflow-y: auto;
  scroll-behavior: smooth;
  margin-bottom: 6%;
}
.schedule-text {
  margin-left: 12%;
  padding-top: 25px;
  padding-bottom: 25px;
  font-weight: 700;
}
.back {
  top: 3% !important;
}
</style>

<template>
  <div class="card-box">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-1 icon">
            <font-awesome-icon icon="stethoscope" />
          </div>
          <div class="col">
            <div class="card-title">{{ item.name }}</div>
            <div class="card-subtitle mb-2 text-muted">{{ item.specialties.join(", ") }}</div>
          </div>
        </div>
        <div v-for="schedule in item.schedules" :key="schedule.id">
          <template v-if="schedule.hours.length > 0">
            <div class="row info">
              <div class="col-1 icon-2">
                <font-awesome-icon icon="building" />
              </div>
              <div class="col office">{{ schedule.office.name }}</div>
            </div>
            <div class="hour-container">
              <template v-for="hour in schedule.hours">
                <div
                  v-if="hour"
                  :key="hour.id"
                  class="text item"
                  :class="isSelected(hour) && 'selected'"
                  @click="select(hour, schedule.office, { id: item.userId, name: item.name }, schedule.day)"
                >
                  {{ hour.start.substring(0,5) }}
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScheduleCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    day() {
      return this.$store.state.day;
    },
  },
  methods: {
    select(hour, office, veterinary, day) {
      this.$store.commit("updateScheduleAvailable", {
        hour: hour,
        veterinary: veterinary,
        office: office,
        day: day.substring(0, 10),
      });
    },
    isSelected(hour) {
      return this.$store.state.scheduleAvailable && this.$store.state.scheduleAvailable.hour.id === hour.id;
    },
  },
};
</script>

<style>
.text {
  font-size: 14px;
  margin: 7px;
  cursor: pointer;
}

.selected {
  background: #49dc49d4 !important;
}
.info {
  padding: 10px 10px;
}
.item {
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid gray;
}
.card-title {
  font-weight: 700;
  margin-bottom: 0;
}
.date {
  color: dodgerblue;
  font-weight: bolder;
  font-style: italic;
}
.icon-2 {
  font-size: 25px;
}
.office {
  font-weight: bolder;
  color: #525252;
  text-transform: uppercase;
  padding-top: 4px;
  margin-left: -15px;
}
.text-muted {
  color: green !important;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 300px;
  margin: 18px;
  margin-left: 9%;
}
.specialties {
  font-size: 12px;
  color: red;
  font-weight: 600;
}
.user {
  font-weight: bolder;
}
.hour-container {
  display: flex;
  flex-wrap: wrap;
}
.card {
  border: none;
}
.card-box {
  margin-bottom: 25px;
  width: 80%;
  margin-left: 12%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.icon {
  font-size: 33px;
}
@media (max-width: 425px) {
  .card-box {
    width: 87%;
    margin-left: 7%;
  }
  .icon {
    margin-right: 9px;
  }
  .icon-2 {
    margin-right: 13px;
  }
}
</style>
